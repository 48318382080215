//
// nav.scss
//

.nav-link {
  @include text-xs(900);
}

// General

.nav:not(.nav-tabs):not(.nav-pills) .nav-link {
  position: relative;
}

.nav:not(.nav-tabs, .nav-pills) .nav-link::after {
  content: "";
  position: absolute;
  top: 100%;
  left: $nav-link-padding-x;
  right: $nav-link-padding-x;
  height: 3px;
  background-color: $primary;
  transform: translateY(.5rem);
  opacity: 0;
  transition: all .1s;
}

.nav:not(.nav-tabs, .nav-pills) .nav-link.active {
  color: $nav-link-active-color;
}

.nav:not(.nav-tabs, .nav-pills) .nav-link.active::after {
  transform: translateY(0);
  opacity: 1;
}