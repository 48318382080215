//
// reboot.scss
//

*:focus {
  outline: none;
}

::selection {
  background-color: $primary;
  color: $white;
}
