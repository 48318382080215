//
// type.scss
//

// Text

.text-xs {
  @include text-xs;
}

// Divider

.hr-sm {
  width: 2rem;
}

.hr-sm:not([size]) {
  height: 3px;
}

// Blockquote

.blockquote > p {

  &::before {
    content: open-quote;
  }

  &::after {
    content: close-quote;
  }
}