//
// background.scss
//

// Cover

.bg-cover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

// Gradient

.bg-gradient-bottom-end-white {
  background-image: 
    linear-gradient(to bottom, rgba($white, 1), rgba($white, .1) 30%, rgba($white, 0)), 
    linear-gradient(to right bottom, rgba($white, 1), rgba($white, 1) 15%, rgba($white, 0) 30%, rgba($white, 0) 100%);
}