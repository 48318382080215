//
// form-control.scss
//

::placeholder {
  font-family: $font-family-serif;
}

.form-control[type="date"],
.form-control[type="time"] {
  max-height: calc(#{$input-line-height} * #{$input-font-size} + #{$input-padding-y} * 2 + #{$input-border-width} * 2);
}